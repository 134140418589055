.swiper-button-prev,
.swiper-button-next {
    color: #fff; /* Adjust color as needed */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.swiper-button-prev {
    left: 10px;
}

.swiper-button-next {
    right: 10px;
}


.image-size {
    max-width: 100%;
    height: auto;
    top: 0;
    left: 0;
   
}
.padding-set{
  padding-left: 10px;
  padding-right: 10px;
}



.over-image {
    /* background-image: url('../../../../AssetsforProjects/alora.jpg'); */
  }
  .logo-width{
   width: 25%;
  }

  .image-sizing{
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100px;
   height: auto;
  }
  .button-color{
   background-color: #29A9AD;
   border-radius: 25px;
   padding: 10px;
   padding-left: 30px;
   padding-right: 30px;
   color: white;
   border: none ;
   margin-left: none;
   transition: all 0.3s linear;
   text-decoration: none;
   
  }
  .button-color:hover{
    background-color: #FECC00;    
    border-radius: 25px;
    
    padding-left: 30px;
    padding-right: 30px;
    color: black;
    border: none ; 
    text-decoration: none;  
    
   }


@media only screen and (max-width: 480px) {
   .image-size{
    max-width: 100%;
   }
    .image-sizing{
   position: absolute;
   top: 0px;
   left: 0px;
   width: 140px;
   height: auto;
   
  }
  .img-view{
    display: none;
  }
  .img-viewmob{
    display: block;
  }

  .mobimg{
   display: block;
   
  }
  .displayimg{   
   display: none;
  }
  .font-size{
   font-size: 30px;
  }
  .logo-width{
    width: 20%;
    margin-top: 12px;
   }
   .padding-set{
    padding-left: 10px;
    padding-right: 25px;
  }
  
   
 }
 @media only screen and (min-width: 481px) and (max-width: 768px) {
    .image-size{
        max-width:100%;
       }
       .font-size{
        font-size: 30px;
       }
       .logo-width{
         width: 20%;
         margin-top: 12px;
        }
        .img-view{
          display: none;
        }
        .img-viewmob{
          display: block;
        }
        .padding-set{
          padding-left: 10px;
          padding-right: 25px;
        }


         .image-sizing{
   position: absolute;
   top: 0px;
   left: 0px;
   width: 100px;
   height: auto;
  }
  .mobimg{
   display: block;
   
  }
  .displayimg{   
   display: none;
  }
 }
 @media only screen and (min-width: 769px) {
   .mobimg{
      display: none;
     }
     .img-viewmob{
      display: none;
    }
 }
 @media only screen and (min-width: 1200px) {
   .mobimg{
      display: none;
     }
     .img-viewmob{
      display: none;
    }
 }