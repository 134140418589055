.font-sizer{
    font-size: 14px;
}
.top-mar{
    margin-top: 60px;
    
}
.margin-imager{
    margin-top: 100px;
}
.hide-number-arrow::-webkit-inner-spin-button,
.hide-number-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-number-arrow {
  -moz-appearance: textfield;
}
@media (max-width: 767px){
    .font-sizer{
        text-align: center;
    }
 
 
}
@media (min-width: 768px) and (max-width: 991px){
  
    .font-sizer{
        text-align: center;
    }
}