.backgrounter{
    background-color: #008f94; 
    /* rgb(45, 95, 99)             */
    /* rgb(24, 78, 83) */
  }
.hide-number-arrow::-webkit-inner-spin-button,
.hide-number-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-number-arrow {
  -moz-appearance: textfield;
}
.button-color{
    background-color: #29A9AD;
    border-radius: 25px;
    /* padding: 5px; */
    /* padding-left: 10px;
    padding-right: 10px; */
    color: white;
    border: none ;
    transition: all 0.3s linear;
    font-size: 9px;
    /* margin-left: 30px;
    margin-bottom: 30px; */
   }
   .button-color:hover{
     background-color: #FECC00;    
     border-radius: 25px;
     /* padding: 5px; */
     /* padding-left: 10px;
     padding-right: 10px; */
     color: black;
     border: none ;
     font-size: 9px;
     /* margin-left: 30px;
     margin-bottom: 30px;     */
    }