.font{
    font-size: 12px;
   
    
}
.accordion-item {
    box-shadow: none;
}

.accordion-button:not(.collapsed){
    background: none;
    color: black;
    box-shadow: none;
    
   
    
}



/* <div class="accordion accordion-flush" id="accordionFlushExample">
<div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            How do I get in touch with you?
        </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">You can reach us at <a href="mailto:enquiry@knsgroup.in">enquiry@knsgroup.in</a> or call us at <a href="tel:+918880664433">8880664433</a>.</div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            What locations are your projects situated at?
        </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">In Bengaluru, our projects are spread across the city. In Mysuru, KNS Ethos is situated in Chamundipuram & KNS Calisto is situated at Kergalli-RT Nagar.</div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            Do you have a loan facility?
        </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">Yes, our projects are approved by most major public and private banks like SBI, HDFC, LIC HFL, ICICI, Dewan Housing, and Motilal Oswal.</div>
    </div>
</div>
</div> */