.width-loft{
    width: 60px;
    padding: 10px;
}
.margin-dhamaka{
    margin-top: 50px;
}
.marign-top{
    margin-top: 50px !important;  
}
.width-copy{
    width: 500px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.width-icon{
    width: 20px;
    margin-left: 30px;
    margin-bottom: 10px;
}
.fot-menu {
    transition: background-color 0.5s;
}

.fot-menu:hover {
    background-color: #c2c1c1; /* Light grey */
    border-radius: 20%;
}
@media (max-width: 767px){
    .margin-dhamaka{
        margin-top: 0px
    }
    .margin-pataka{
        margin-top: 50px;
    }
    /* .flex-adjust{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        padding: 10px;
    } */
    .width-copy{
        width: 300px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .width-icon{
        width: 6px;
        margin-left: 30px;
        margin-bottom: 10px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .margin-dhamaka{
        margin-top: 10px;
        
    }
    .margin-pataka{
        margin-top: 50px;
       
    }
    /* .flex-adjust{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        padding: 10px;
    } */
    .width-icon{
        width: 10px;
        margin-left: 30px;
        margin-bottom: 10px;
    }
}
/* Styles for screens between 992px and 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
    /* Add your styles here */
  }
  
  /* Styles for screens larger than 1199px */
  @media (min-width: 1200px) {
    /* Add your styles here */
  }



  .bgcolor{
    background-color: rgb(247, 247, 247);
}
.widthlogo {
    width: 35px;
    height: auto;
    margin-top: 30px;
    margin-bottom: 50px;
    margin-left: 20px;
}
.width-happy{
    margin-bottom: 30px;

}
.fot-menu{
    color: black;
    transition: all 0.3s linear;
    font-size: 10px;
}
.fot-menu:hover{
    color: #29A9AD;;
}
.an-color{
    color: black;
    text-decoration: none;
    font-weight: 700;
    transition: all 0.3s linear;
}
.an-color:hover{
    color: #29A9AD;;
    text-decoration: none;
    font-weight: 700;

}
.margin-end{
    margin-right: 20px;
}
.width-copy{
    width: 450px;

}

.margin-set{
    margin-top: 10px;
}






  /* Styles for screens smaller than 768px */
  @media (max-width: 767px) {
    .fot-menu{
        color: black;
        transition: all 0.3s linear;
        font-size: 12px;
    } 
    .text-sizing{
        font-size:8px;
    }
    .margin-life{
        margin-top: -40px;
    }
    .width-copy{
        width: 250px;

    }
    .margin-set{
        margin-top: 6px;
    }


    }

    /* Styles for screens between 768px and 991px */
    @media (min-width: 768px) and (max-width: 991px) {
      /* Add your styles here */
    }

    /* Styles for screens between 992px and 1199px */
    @media (min-width: 992px) and (max-width: 1199px) {
      /* Add your styles here */
    }

    /* Styles for screens larger than 1199px */
    @media (min-width: 1200px) {
      /* Add your styles here */
    }