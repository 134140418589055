.width-size{
    width: 75%;
}
.margin-life{
    margin-top: 120px;
    margin-bottom: 60px;
}
.h1-confi{
    color: #29A9AD;
    /* font-size: 50px; */
    font-weight: 800;
    width: 100%;
    margin-bottom: -5px;
}
@media (max-width: 767px){
    .margin-life{
        margin-top: 400px;
        margin-bottom: 60px;
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .margin-life{
        margin-top: 400px;
        margin-bottom: 60px;
    }
}
