.bg-one{
    background-color: #CFE5E6;
    

}
.margin-minus{
    margin-top: -250px;
   
    
    
}

.margintop{
    margin-top: 150px;
    padding-right: 80px;
    
}
.margin-side{
    margin-left: 150px;
}
.modal-body {
    height: 400px;
    overflow: hidden;
   
  }
  .modal-body:hover{
    overflow-y: auto;
  }
  .button-color{
   background-color: #29A9AD;
   border-radius: 25px;
   padding: 10px;
   padding-left: 30px;
   padding-right: 30px;
   color: white;
   border: none ;
   margin-left: none;
   transition: all 0.3s linear;
  }
  .button-color:hover{
    background-color: #FECC00;    
    border-radius: 25px;
    
    padding-left: 30px;
    padding-right: 30px;
    color: black;
    border: none ;    
   }


   .margin-setup{
     margin-left: 80px;
     padding-top: 120px;
     padding-bottom: 120px;
     width:700px;
   }

   .margin-overloop{
    margin-left: 25%;
    width: 200px;
    height: auto;
    margin-top: -800px;    
   }
   .margin-modal{
    margin-top: -40px;
   }
   .sizing-close{
    margin-top: 10px;
    margin-right: 10px;
   }
   .scroll{
    height: 500px;
   }
   .margin-maping{
    margin-top: 100px;
    margin-bottom: 50px;
   }
   .margin-button  {
    margin-top: 120px;
    margin-bottom: 30px;
   }



   


   /* image paan effect here */

 /* Styles for screens smaller than 768px */
@media (max-width: 767px) {
  .margin-setup{
    margin-left: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 350px;
    height: auto;
  }
  .margin-minus{
    margin-top: 0px;
    width: 100%;
    
  }
  .font-sizeing{
    font-size: 14px;
  }
  .button-color{
    background-color: #29A9AD;
    border-radius: 25px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    border: none ;    
    transition: all 0.3s linear;
   }
   .scroll{
    height: 220px;
   }
   .margin-maping{
    margin-top: 50px;
    margin-bottom: 50px;
   }
   .margin-button  {
    margin-top: 120px;
    margin-bottom: 30px;
   }

  

   .margintop{
    margin-top: 30px;
    padding: 30px;
    
}
  .margin-side{
    margin-left: 0px;
    
}

.button-color{
  background-color: #29A9AD;
  border-radius: 25px;
  /* padding: 5px; */
  /* padding-left: 10px;
  padding-right: 10px; */
  color: white;
  border: none ;
  transition: all 0.3s linear;
  font-size: 9px;
  /* margin-left: 30px;
  margin-bottom: 30px; */
 }
 .button-color:hover{
   background-color: #FECC00;    
   border-radius: 25px;
   /* padding: 5px; */
   /* padding-left: 10px;
   padding-right: 10px; */
   color: black;
   border: none ;
   font-size: 9px;
   /* margin-left: 30px;
   margin-bottom: 30px;     */
  }

  .margin-overloop{
    margin-left: 0px;
    width: 80px;
    height: auto;
    margin-top: 0px;    
   }
   .img-over{
   margin-left:22%;
   width: 120px;
   margin-top: -400px;
   }

}

/* Styles for screens between 768px and 991px */
@media (min-width: 768px) and (max-width: 991px) {
  .margin-button  {
    margin-top: 60px;
    margin-bottom: 30px;
   }
   .font-sizeing{
    font-size: 16px;
  }
}

/* Styles for screens between 992px and 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
  /* Add your styles here */
}

/* Styles for screens larger than 1199px */
@media (min-width: 1200px) {
  /* Add your styles here */
}
  
   
  

/* all new css here */

.re-arrange{

  position: absolute;
  margin-top: -600px;
  width: 150px;

}
.re-arrangeone{
  margin-top: -380px;
  width: 75%;
}
.img-show{
  display: none;
}
.img-hide{
  display: block;
}

/* Styles for screens smaller than 768px */
@media (max-width: 767px) {

  .re-arrange{

    position: absolute;
    margin-top: -500px;
    width: 130px;
    height: auto;
  
  }
  .re-arrangeone{
    margin-top: 0px;
    width: 100%;
  }
  .img-show{
    display: block;
  }
  .img-hide{
    display: none;
  }
  

}
/* Styles for screens between 768px and 991px */
@media (min-width: 768px) and (max-width: 991px) {
  .re-arrange{

    position: absolute;
    margin-top: -900px;
    width: 180px;
    height: auto;
  
  }
  .re-arrangeone{
    margin-top: 0px;
    width: 100%;
  }
  .margin-setup{
    margin-left: 100px;
    padding-top: 120px;
    padding-bottom: 120px;
    width:600px;
  }
  
}


/* Styles for screens between 992px and 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
  .re-arrange{

    position: absolute;
    margin-top: -500px;
    width: 130px;
    height: auto;
  
  }
  .re-arrangeone{
    margin-top: 200px;
    width: 100%;
  }

  
}

/* Styles for screens larger than 1199px */
@media (min-width: 1200px) {
  /* Add your styles here */
}