@media (max-width: 767px){
    .margin-under{
        margin-top: 160px;
        margin-bottom: 150px;
    }

}
@media (min-width: 768px) and (max-width: 991px) {
    .margin-under{
        margin-top: 160px;
        margin-bottom: 150px;
    }

}

