
.wid-margin{
    width:10px;
    height: auto;
    margin-top: 10px;
    margin-left: 10px;
}
.icon-margin{
  width:40px;
    height: auto;
    margin-top: 10px;
    margin-left: 10px;
}
.margin-think{
  margin-left: -820px;
  width: 300px;
  
}
.text-setup{
  color: rgb(138, 138, 138);
}

/* Styles for screens smaller than 768px */
@media (max-width: 767px) {
    .wid-margin{        
    width:50px;
    height: auto;
    margin-top: 3px;
    margin-left: 12px;
    }
    .icon-margin{
      width:25px;
        height: auto;
        margin-top: 10px;
        margin-left: 10px;
    }
    .margin-think{        
      margin-left: auto;
      width: 200px;
      
    }
    
  }
  
  /* Styles for screens between 768px and 991px */
  @media (min-width: 768px) and (max-width: 991px) {
    /* Add your styles here */
    .margin-think{        
      margin-left: auto;
      width: 200px;
      
    }
    .icon-margin{
      width:32px;
        height: auto;
        margin-top: 10px;
        margin-left: 10px;
    }
  
  }