
.image-sizing{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100px;
  height: auto;
  /* z-index:10; */
 }

.swiper-button-prev,
.swiper-button-next {
    color: #fff; /* Adjust color as needed */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}


.swiper-button-prev {
    left: 10px;
}

.swiper-button-next {
    right: 10px;
}



.margin-think{
    margin-left: -700px;
    width: 300px;
    
}
.carousel-control-prev,
.carousel-control-next {
  display: none !important; 
}
.video-setuptwo{
 
  display: none;
}
.desk{
  display: block;
}
.mob{
  display: none;
}
.carousel{
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Styles for screens smaller than 768px */
@media (max-width: 767px) {
    .margin-think{        
      margin-left: auto;
      width: 200px;
      
    }
    .video-setup{
      display: none;
    }
    .video-setuptwo{
      
      display: block;
    }
    .desk{
      display: none;
    }
    .mob{
      display: block;
    }
   
  }
  
  /* Styles for screens between 768px and 991px */
  @media (min-width: 768px) and (max-width: 991px) {
    .desk{
      display: none;
    }
    .mob{
      display: block;
    }
  }
  
  /* Styles for screens between 992px and 1199px */
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-think{
      margin-left: -500px;
      width: 300px;
      
  }
  }
  
  /* Styles for screens larger than 1199px */
  @media (min-width: 1200px) {
    .margin-think{
      margin-left: -650px;
      width: 300px;
      
  }
  }



.wid-margin{
    width:70px;
    height: auto;
    margin-top: 10px;
    margin-left: 20px;
}
/* Styles for screens smaller than 768px */
@media (max-width: 767px) {
    .wid-margin{        
    width:50px;
    height: auto;
    margin-top: 3px;
    margin-left: 12px;
    }
  }
  
  /* Styles for screens between 768px and 991px */
  @media (min-width: 768px) and (max-width: 991px) {
    /* Add your styles here */
  }
  
  /* Styles for screens between 992px and 1199px */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* Add your styles here */
  }
  
  /* Styles for screens larger than 1199px */
  @media (min-width: 1200px) {
    /* Add your styles here */
  }





.knslogo-width{
    width: 50px;
    margin-left: 30px;
}
.text-decoration{
    text-decoration: none;
    color:  rgb(0, 0, 0);
    padding: 30px;
    transition: all 0.3s linear;    
}
.text-decoration:hover{
    text-decoration: none;
    color: #29A9AD;
    
}
