.form-design{
    border: none;
    border-bottom: 1px solid #29A9AD;
    border-radius: 0px;
    
    margin-top: 50px;
    padding-left: 0px;
   
    
   
}
.border-black {
  border: 2px solid black; /* Black border */
}

.hide-number-arrow::-webkit-inner-spin-button,
.hide-number-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-number-arrow {
  -moz-appearance: textfield;
}
.text-dec{
    text-decoration: none;
    color: #29A9AD;
}
.margin-end{
    margin-right: 130px;
}

.button-color{
    background-color: #29A9AD;
    border-radius: 25px;
    /* padding: 5px; */
    /* padding-left: 10px;
    padding-right: 10px; */
    color: white;
    border: none ;
    transition: all 0.3s linear;
    font-size: 10px;
    /* margin-left: 30px;
    margin-bottom: 30px; */
   }
   .button-color:hover{
     background-color: #FECC00;    
     border-radius: 25px;
     /* padding: 5px; */
     /* padding-left: 10px;
     padding-right: 10px; */
     color: black;
     border: none ;
     font-size: 10px;
     /* margin-left: 30px;
     margin-bottom: 30px;     */
    }
    .padding-setup{
        margin-bottom: -30px;
    }
    button.disabled {
     
        background-color:#29A9AD;
        border-radius: 20px;
        color: rgb(0, 0, 0);
        padding-left: 30px;
        padding-right: 30px;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
        transition: all 0.3s linear;
      }
      button.disabled:hover {
     
        background-color:#FECC00;
        border-radius: 20px;
        color: rgb(0, 0, 0);
        padding-left: 30px;
        padding-right: 30px;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
      }
      button.enabled{
        background-color: #29A9AD;
        border-radius: 20px;
        color: aliceblue;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
        transition: all 0.3s linear;

      }
      button.enable:hover {
     
        background-color:#FECC00;
        border-radius: 20px;
        color: rgb(0, 0, 0);
        padding-left: 30px;
        padding-right: 30px;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
      }
