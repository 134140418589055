*{
  font-family: 'Poppins', sans-serif;
  
  
}
body,html {
  overflow-x:hidden;
}


.swiper-button-next,
.swiper-button-prev{
  color: white !important;
  
}
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .button-color{
  background-color: #29A9AD;
  border-radius: 25px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  border: none ;
  margin-left: none;
  transition: all 0.3s linear;
 }
 .button-color:hover{
   background-color: #FECC00;    
   border-radius: 25px;
   
   padding-left: 30px;
   padding-right: 30px;
   color: black;
   border: none ;    
  } */
