.postposition{
  position: relative;
  z-index: 2;
}
.margin-imglife{
  
    margin-left: -500px;
    width: 300px;
    
}


/* Styles for screens smaller than 768px */
@media (max-width: 767px) {
 
    .margin-imglife{
        margin-left: auto;
        width: 200px;
    }
   
   
  }
  
  /* Styles for screens between 768px and 991px */
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-imglife{
        margin-left: -200px;
        width: 200px;
    }
  }
  
  /* Styles for screens between 992px and 1199px */
  @media (min-width: 992px) and (max-width: 1199px) {
  
  .margin-imglife{
    margin-left: -400px;
    width: 300px;
}
  }
  
  /* Styles for screens larger than 1199px */
  @media (min-width: 1200px) {
  
 
  }











