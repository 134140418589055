.font-sizer{
    font-size: 14px;
}
.top-mar{
    margin-top: 60px;
    
}
.margin-imager{
    margin-top: 100px;
}
.hide-number-arrow::-webkit-inner-spin-button,
.hide-number-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hide-number-arrow {
  -moz-appearance: textfield;
}
@media (max-width: 767px){
    .font-sizer{
        text-align: center;
    }
 
 
}
@media (min-width: 768px) and (max-width: 991px){
  
    .font-sizer{
        text-align: center;
    }
}
/* Filter buttons styling */
.filter-buttons {
    margin: 20px 0;
}

.filter-btn {
    background-color: #8C6E41;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 20px;
}

.filter-btn:hover, .filter-btn.active {
    background-color: #6B4E29;
}

.plans {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap:wrap;
}

.plan-item {
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 45%;
    border-radius: 8px;
}

.plan-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.plan-item p {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}

/* Pagination buttons */
.pagination {
    margin-top: 20px;
}

.pagination-btn {
    background-color: #ccc;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 50%;
}

.pagination-btn:hover {
    background-color: #aaa;
}

/* Modal CSS */
.modal {
    position: fixed;
    z-index: 1000; /* Make sure modal appears above everything else */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
  



  .custom-gap {
    margin-bottom: 20px; /* Adjust the value to your liking */
}


.image-hover-effect {
    transition: transform 0.3s ease;
    cursor: pointer; /* Change cursor to hand on hover */

   
}

.image-hover-effect:hover {
    transform: scale(1.1); /* Scale the image up by 10% on hover */
}

.display-block{
    display: block;
}