.button-color{
    background-color: #29A9AD;
    border-radius: 25px;
    padding: 10px;
    font-size: 12px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    border: none ;
    margin-left: none;
    transition: all 0.3s linear;
   }
   .button-color:hover{
     background-color: #FECC00;    
     border-radius: 25px;
     font-size: 12px;
     padding-left: 30px;
     padding-right: 30px;
     color: black;
     border: none ;    
    }