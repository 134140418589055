/* body {
    background-image: url('../../../Assets//backgallery.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  } */

  .backgrounder{
    /* background: url(../../../Assets//backgallery.jpg) no-repeat  fixed; */
    background-color: beige;
    background-size: cover;
    height: 100vh;
  }

  .position-setup{
    /* position: relative; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* align-items: center; */
    width: 100%;
    height: 100vh;
    margin-top: 200px;
    margin-bottom: 250px;
    /* background-color: black; */
    
}

.position-setup-1{
  margin-top: 50px !important;
}
/* .margin-fotter{
    margin-bottom: 350px;
} */
.jadu{
  width: 250px;
  /* margin: 40px; */
}

.jadu:hover{
  background-color: rgb(222, 248, 248);
  border-radius: 10px;
  /* padding-left: 30px; */
  width: 230px
}
.buttonone-color{
  background-color: #29A9AD;
  border-radius: 10px;

  
  color: white;
  border: none ;
  margin-left: none;
  transition: all 0.3s linear;
  width:150px;
  

  
 }
 .button-colorone:hover{
   background-color: #FECC00;    
   border-radius:10px;
   
   color: black;
   border: none ;
      
  } 
  .font-sizes{
    font-size: 20px;
  }
  .display-set{
    display: block;

  }
  .display-setmob{
    display: none;
    
  }



    /* Styles for screens smaller than 768px */
    @media (max-width: 767px) {
      .backgrounder{
        /* background: url(../../../AssetsforGallery/mobgallery.jpg) no-repeat  fixed; */
        background-color: beige;
        background-size: cover;
        height: 100vh;
      }
      .position-setup{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        /* height: 100%; */
        /* margin-top: 400px;
        margin-bottom: 170px; */
        /* padding: 100px; */
        padding-top: 100px;
        
    }
    /* .margin-fotter{
      margin-bottom: 100px;
  } */
  .display-set{
    display: none;

  }
  .display-setmob{
    display: block;
    
  }
 
     
      }
      
      /* Styles for screens between 768px and 991px */
      @media (min-width: 768px) and (max-width: 991px) {
        .backgrounder{
          /* background: url(../../../AssetsforGallery/mobgallery.jpg) no-repeat  fixed; */
          background-color: beige;
          background-size: cover;
          height: 100vh;
        }
        
        .position-setup{
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          /* height: 100%; */
          /* margin-top: 400px;
          margin-bottom: 170px; */
          /* padding: 100px; */
          padding-top: 200px;
          
      }
        
        .display-set{
          display: none;
      
        }
        .display-setmob{
          display: block;
          
        }

        /* Add your styles here */
      }
      
      /* Styles for screens between 992px and 1199px */
      @media (min-width: 992px) and (max-width: 1199px) {
        /* Add your styles here */
      }
      
      /* Styles for screens larger than 1199px */
      @media (min-width: 1200px) {
        /* Add your styles here */
      }




 
