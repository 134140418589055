
.modal-backdrop {
    background-color: transparent !important;
    
    border: none;
  }
  
  .desk-view{
    display: block;
}
.mob-view{
    display: none;
}
@media only screen and (max-width: 480px){
    .mob-view{
        display: block;
    }
    .desk-view{
        display: none;
    }
}
@media only screen and (min-width: 481px) and (max-width: 768px){
    .mob-view{
        display: block;
    }
    .desk-view{
        display: none;
    }
}