.button-color{
    background-color: #29A9AD;
    border-radius: 25px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    border: none ;
    margin-left: none;
    transition: all 0.3s linear;
    
   }
   .button-color:hover{
     background-color: #FECC00;    
     border-radius: 25px;
     
     padding-left: 30px;
     padding-right: 30px;
     color: black;
     border: none ;    
    }
    .display-none{
        display: none;
    }
    .display-block{
        display: block;
    }
    .button-active {
        background-color:#FECC00 ;
        border-radius: 25px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    border: none ;
    margin-left: none;
    transition: all 0.3s linear;
    }
    .sizer-font{
        font-size: 10px;
    }
    @media (max-width: 767px){
        .flexdirect{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
           
            
        }
        .button-width{
            width: 50%;
        }
     
    }

    .center-text {
        text-align: center;
    }
    
    @media (min-width: 768px) and (max-width: 991px){
        .flexdirect{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
           
            
        }
        .button-width{
            width: 50%;
        }
     
    }
 