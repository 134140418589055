.button-simple{
    background-color: rgba(255, 255, 255, 0.315);    
    position: absolute;
    width: 150px;
    font-size: 14px;    
    margin-top: 380px;
    /* margin-left: 30%; */
    /* transition: color 0.4s linear; */
    transition: all 0.2s linear;
    border-radius: 100px;
    
}
.button-simple:hover{
    background-color: rgba(2, 2, 2, 0.315);    
    position: absolute;
    width: 200px;
    font-size: 14px;
    color: white;  
    margin-top: 380px;
    /* margin-left: 30%; */
    border-radius:  100px;
}
.button-simple1{
    background-color: rgba(255, 255, 255, 0.315);    
    position: absolute;
    width: 150px;
    font-size: 14px;    
    margin-top: 450px;
    /* margin-right: 30%; */
    /* transition: color 0.4s linear; */
    transition: all 0.2s linear;
    border-radius: 100px;
}
.button-simple1:hover{
    background-color: rgba(2, 2, 2, 0.315);    
    position: absolute;
    width: 200px;
    font-size: 14px;
    color: white;  
    margin-top: 450px;
    /* margin-right: 30%; */
    border-radius:  100px;
  
}


/* link style */



.margin{
    margin-bottom: 20px;
}
.text-width{
    font-weight: 700;
}
.pad-left{
    padding-left: 150px;
}
.pad-right{
    padding-right: 150px;
}
.margin-location{
  margin-top: 200px;
}

.margin-top {
  margin-top: 50px;
}


.image-container {
  position: relative;
  
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.image-container:hover img {
  transform: scale(1.04); /* adjust the scale factor to your liking */
}
 


  /* Styles for screens smaller than 768px */
@media (max-width: 767px) {
.margin-top {
  margin-top: 300px;
}


.button-simple{
  background-color: rgba(255, 255, 255, 0.315);    
  position: absolute;
  width: 100px;
  font-size: 12px;    
  margin-top: 220px;
  /* margin-left: 30%; */
  /* transition: color 0.4s linear; */
  transition: all 0.2s linear;
  border-radius: 100px;
}
.button-simple:hover{
  background-color: rgba(2, 2, 2, 0.315);    
  position: absolute;
  width: 150px;
  font-size: 12px;
  color: white;  
  margin-top: 220px;
  /* margin-left: 30%; */
  border-radius:  100px;
}
.button-simple1{
  background-color: rgba(255, 255, 255, 0.315);    
  position: absolute;
  width: 100px;
  font-size: 12px;    
  margin-top: 230px;
  /* margin-right: 30%; */
  /* transition: color 0.4s linear; */
  transition: all 0.2s linear;
  border-radius: 100px;
}
.button-simple1:hover{
  background-color: rgba(2, 2, 2, 0.315);    
  position: absolute;
  width: 150px;
  font-size: 12px;
  color: white;  
  margin-top: 230px;
  /* margin-right: 30%; */
  border-radius:  100px;
}







}

/* Styles for screens between 768px and 991px */
@media (min-width: 768px) and (max-width: 991px) {
  .button-simple{
    background-color: rgba(255, 255, 255, 0.315);    
    position: absolute;
    width: 150px;
    font-size: 14px;    
    margin-top: 450px;
    /* margin-left: 30%; */
    /* transition: color 0.4s linear; */
    transition: all 0.2s linear;
    border-radius: 100px;
    
}
.button-simple:hover{
    background-color: rgba(2, 2, 2, 0.315);    
    position: absolute;
    width: 200px;
    font-size: 14px;
    color: white;  
    margin-top:450px;
    /* margin-left: 30%; */
    border-radius:  100px;
}
}


/* Styles for screens between 992px and 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
  .button-simple{
    background-color: rgba(255, 255, 255, 0.315);    
    position: absolute;
    width: 150px;
    font-size: 14px;    
    margin-top:300px;
    /* margin-left: 30%; */
    /* transition: color 0.4s linear; */
    transition: all 0.2s linear;
    border-radius: 100px;
    
}
.button-simple:hover{
    background-color: rgba(2, 2, 2, 0.315);    
    position: absolute;
    width: 200px;
    font-size: 14px;
    color: white;  
    margin-top:300px;
    /* margin-left: 30%; */
    border-radius:  100px;
}
}

/* Styles for screens larger than 1199px */
@media (min-width: 1200px) {
  /* Add your styles here */
}






